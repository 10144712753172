function strip0x(input) {
  return input.replace(/^0x/, '');
}

function addTxHashToData(_ref) {
  var encodedData = _ref.encodedData,
      transactionHash = _ref.transactionHash;
  return encodedData.slice(0, 2) + strip0x(transactionHash) + encodedData.slice(2);
}

function parseAMBMessage(message) {
  message = strip0x(message);
  var txHash = "0x".concat(message.slice(0, 64));
  var sender = "0x".concat(message.slice(64, 104));
  var executor = "0x".concat(message.slice(104, 144));
  return {
    sender: sender,
    executor: executor,
    txHash: txHash
  };
}

module.exports = {
  addTxHashToData: addTxHashToData,
  parseAMBMessage: parseAMBMessage,
  strip0x: strip0x
};