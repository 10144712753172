import React from 'react'
import disclaimerIcon from '../assets/images/disclaimer-modal/disclaimer@2x.png'

export const Disclaimer = ({ onConfirmation }) => (
  <div className="disclaimer-alert">
    <div className="image-container">
      <img className="disclaimer-icon" src={disclaimerIcon} alt="disclaimer icon" />
    </div>
    <div className="alert-container">
      <span className="disclaimer-title">
	Decommissioning EnergyWeb Bridge 
      </span>
	<p className="disclaimer-description">
	<em>
	<b>
	<a href="http://bridge.energyweb.org"> EnergyWeb Bridge</a> will be no longer supporting EWC -> ETH bridging of $EWT. <br/>
		Please bridge your $EWTB tokens back to EWC chain using <a href="http://bridge.energyweb.org"> EnergyWeb Bridge</a> to get your native $EWT before it gets decommissioned. <br/>
		In the future, Please use <a href="https://alliancebridge.io/" target="_blank">AllianceBlock Bridge</a> for bridging
	</b>
	</em> 
	</p>
	<div className="disclaimer-buttons">
        <button className="disclaimer-confirm" onClick={onConfirmation}>
          Continue
        </button>
      </div>
    </div>
  </div>
)
